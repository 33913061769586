<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex shrink>
        <v-card class="rounded-xl" elevation="12" width="500" style="border: #757575 1px solid !important;">
          <v-card-text>
            <!-- <v-layout class="mt-3" row justify-center>
              <v-flex shrink>
                <v-img alt="Prepaylink logo" width="100" class="shrink text-center" contain
                  src="@/assets/fulllogo.png" />
              </v-flex>
            </v-layout> -->

            <v-card-text class="mt-5">
              <div>Business Information</div>
              <v-row class="mt-1">
                <v-col cols="6">
                  <v-text-field v-model="Customer.firstName" outlined dense :hide-details="emailErrors.length === 0"
                    :error-messages="firstNameErrors" label="First Name"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="Customer.lastName" outlined dense :hide-details="emailErrors.length === 0"
                    :error-messages="lastNameErrors" label="Last Name"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="Customer.businessName" outlined dense :hide-details="emailErrors.length === 0"
                    :error-messages="businessNameErrors" label="Business Name"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="Customer.phoneNumber" outlined dense :hide-details="emailErrors.length === 0"
                    :error-messages="phoneNumberErrors" label="Phone Number"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="Customer.email" outlined dense :hide-details="emailErrors.length === 0"
                    :error-messages="emailErrors" label="Email Address"></v-text-field>
                </v-col>
              </v-row>

              <div class="mt-5">Address</div>
              <v-row class="mt-1">
                <v-col cols="12">
                  <v-text-field v-model="Customer.Address.addressLine1" outlined dense
                    :hide-details="emailErrors.length === 0" label="Address"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="Customer.Address.city" outlined dense :hide-details="emailErrors.length === 0"
                    label="City"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="Customer.Address.postalCode" outlined dense
                    :hide-details="emailErrors.length === 0" label="Zip Code"></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn text @click="login">Login</v-btn>
              <v-spacer></v-spacer>
              <v-btn :loading="loading" v-on:click.stop="submit" color="primary">Submit</v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import authService from "../service";
const { validationMixin } = require('vuelidate');
const {
  required,
  minLength,
  email,
  requiredIf,
} = require('vuelidate/lib/validators');

export default {
  name: "authentication-login",
  data() {
    return {
      Customer: {
        firstName: null,
        lastName: null,
        businessName: null,
        phoneNumber: null,
        Address: {
          CountryCode: 840
          // addressLine1: null,
          // city: null,
          // postalCode: null
        },
        email: null,
        code: process.env.VUE_APP_LOCATION_CODE,
      },
      loading: false,
      showPassword: false,
      email: null,
      password: null,
    };
  },
  components: {},
  async created() {
    localStorage.removeItem(`${process.env.VUE_APP_STORAGE_PREFIX}token`);
  },
  methods: {
    ...mapActions("global", ["setContact"]),
    login() {
      this.$router.push({ name: 'authentication-login' });
    },
    reset() {
      this.Customer = {
        firstName: null,
        lastName: null,
        businessName: null,
        phoneNumber: null,
        Address: {
          CountryCode: 840
          // addressLine1: null,
          // city: null,
          // postalCode: null
        },
        email: null,
        code: process.env.VUE_APP_LOCATION_CODE,
      }

    },
    submit() {
      this.$v.$touch();
      // if (this.email) this.email = this.email.toLowerCase();
      if (this.Customer.email) this.Customer.email = this.Customer.email.toLowerCase();
      if (this.$v.$invalid) {
        this.$toast.open({
          message: 'Require fields missing',
          dismissible: true,
          type: 'error',
          position: 'top-right',
        });
        // this.$swal
        //   .mixin({
        //     toast: true,
        //     position: "top-end",
        //     showConfirmButton: true,
        //     timer: 2000,
        //   })
        //   .fire({
        //     type: "error",
        //     title: "Invalid Input",
        //   });
      } else {
        this.loading = true;
        this.$Progress.start();
        return authService
          .signup(this.Customer)
          .then(async (response) => {
            console.log("response", response);
            this.$Progress.finish();
            this.loading = false
            
            this.$toast.open({
              message: 'Signed up successfully!',
              dismissible: true,
              type: 'success',
              position: 'top-right',
            });
            this.reset();
            this.login()
            // this.$swal
            //   .mixin({
            //     toast: true,
            //     position: "centre",
            //     showConfirmButton: true,
            //     timer: 2000,
            //   })
            //   .fire({
            //     type: "success",
            //     title: "Signed up successfully!",

            //   });
          })
          .catch((error) => {
            this.loading = false;
            this.$Progress.finish();
            console.log('error', error);
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: true,
                timer: 2000,
              })
              .fire({
                type: "error",
                title: error.data ? error.data.message : error.message,
              });
          });
      }
    },
  },
  computed: {
    ...mapGetters("global", ["getContactNumber"]),
    firstNameErrors() {
      const errors = [];
      if (!this.$v.Customer.firstName.$dirty) return errors;
      if (!this.$v.Customer.firstName.required)
        errors.push('Field is required');
      if (!this.$v.Customer.firstName.minLength)
        errors.push('Field must be two characters long');
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.Customer.lastName.$dirty) return errors;
      if (!this.$v.Customer.lastName.minLength)
        errors.push('Field must be two characters long');
      return errors;
    },
    businessNameErrors() {
      const errors = [];
      if (!this.$v.Customer.businessName.$dirty) return errors;
      if (!this.$v.Customer.businessName.required)
        errors.push('Field is required');
      if (!this.$v.Customer.businessName.minLength)
        errors.push('Field must be two characters long');
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.Customer.phoneNumber.$dirty) return errors;
      if (!this.$v.Customer.phoneNumber.minLength)
        errors.push('Phone number can not be less than 10');
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.Customer.email.$dirty) return errors;
      if (!this.$v.Customer.email.email)
        errors.push('Email is not valid');
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.Customer.password.$dirty) return errors;
      if (!this.$v.Customer.password.required) errors.push("Password is required.");
      return errors;
    },
  },
  mixins: [validationMixin],
  validations: {
    Customer: {
      firstName: {
        required,
        minLength: minLength(2),
      },
      businessName: {
        required,
        minLength: minLength(2),
      },
      lastName: {
        minLength: minLength(1),
      },
      phoneNumber: {
        minLength: minLength(10),
      },
      email: {
        email,
      },
      Address: {
        addressLine1: {},
        city: {},
      },
    },
  },
};
</script>

<style scoped>
/* #login {
  background-image: url(https://picsum.photos/1200/?random&blur);
  background-size: cover;
} */
</style>


